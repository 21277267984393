<template>
  <div class="container home p-5 p-sm-6">
    <TagFilter :items="tags" v-on:tag-selected="switchCategory" />
    <div class="columns is-multiline is-centered">
      <div class="column is-full is-two-thirds-widescreen">
        <template v-for="(post, index) in posts">
          <TimeAgo
            :current="post.date"
            :previous="index > 0 ? posts[index - 1].date : null"
            :key="index"
          />
          <PostPreview class="py-2" :post="post" :key="post.id" />
        </template>
      </div>
    </div>
    <div class="has-text-centered mt-4">
      <button
        v-if="mayHasMore"
        class="button is-primary is-light"
        @click="getMorePosts"
      >
        More posts
      </button>
    </div>
  </div>
</template>

<script>
import TimeAgo from "@/components/TimeAgo.vue";
import TagFilter from "@/components/TagFilter.vue";
import PostPreview from "@/components/PostPreview.vue";
import wp from "@/plugins/wordpress.js";

const postPerPage = 10;

export default {
  name: "Home",
  components: {
    PostPreview,
    TagFilter,
    TimeAgo,
  },
  data: function () {
    return {
      posts: [],
      currentCategory: null,
      mayHasMore: false,
      tags: [],
      page: 1,
    };
  },
  created: async function () {
    try {
      [this.posts, this.tags] = await Promise.all([
        this.getPosts(),
        await wp.categories().get(),
      ]);
    } catch (error) {
      return;
    }

    this.mayHasMore = this.posts.length >= postPerPage * this.page;
  },
  methods: {
    getPosts: function () {
      const options = { per_page: postPerPage, page: this.page, _embed: true };

      if (this.currentCategory) {
        options.categories = [this.currentCategory];
      }
      return wp.posts().get(options);
    },
    switchCategory: async function (id) {
      this.currentCategory = Number.isInteger(id) ? id : null;
      this.page = 1;
      this.posts = await this.getPosts();
    },
    getMorePosts: async function () {
      let newPosts;
      this.page++;
      try {
        newPosts = await this.getPosts();
      } catch (error) {
        if (error.code == "rest_post_invalid_page_number") {
          this.mayHasMore = false;
        }
        return;
      }
      this.posts = this.posts.concat(newPosts);
    },
  },
};
</script>
