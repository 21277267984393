import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";

import Icon from "@/components/Icon.vue";
import ChevronBackOutline from "@/components/icons/ChevronBackOutline.vue";
import ChevronForwardOutline from "@/components/icons/ChevronForwardOutline.vue";
import CloudDownloadOutline from "@/components/icons/CloudDownloadOutline.vue";
import ChatbubblesOutline from "@/components/icons/ChatbubblesOutline.vue";
import ArrowBackOutline from "@/components/icons/ArrowBackOutline.vue";
import ArrowForwardOutline from "@/components/icons/ArrowForwardOutline.vue";
import LogoWhatsapp from "@/components/icons/LogoWhatsapp.vue";
import ImageOutline from "@/components/icons/ImageOutline.vue";
import MapOutline from "@/components/icons/MapOutline.vue";
import RefreshOutline from "@/components/icons/RefreshOutline.vue";
import ExpandOutline from "@/components/icons/ExpandOutline.vue";
import CodeOutline from "@/components/icons/CodeOutline.vue";
import HeartOutline from "@/components/icons/HeartOutline.vue";

import "img-comparison-slider";

Vue.config.productionTip = false;
Vue.config.ignoredElements = [/^ion-/, /test-\w*/, /img-comparison-slider/];

Vue.use(require("vue-moment"));

Vue.component("Icon", Icon);
Vue.component("ChevronBackOutline", ChevronBackOutline);
Vue.component("ChevronForwardOutline", ChevronForwardOutline);
Vue.component("CloudDownloadOutline", CloudDownloadOutline);
Vue.component("ExpandOutline", ExpandOutline);
Vue.component("ChatbubblesOutline", ChatbubblesOutline);
Vue.component("ArrowBackOutline", ArrowBackOutline);
Vue.component("ArrowForwardOutline", ArrowForwardOutline);
Vue.component("LogoWhatsapp", LogoWhatsapp);
Vue.component("ImageOutline", ImageOutline);
Vue.component("MapOutline", MapOutline);
Vue.component("RefreshOutline", RefreshOutline);
Vue.component("CodeOutline", CodeOutline);
Vue.component("HeartOutline", HeartOutline);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
