<template>
  <div class="columns is-gapless is-multiline is-mobile preview-grid-wrapper">
    <div
      v-for="n in maxPreviewItems"
      :key="n"
      :class="`column preview-grid is-half picture-${n}`"
      :style="`background-image: url(${pictures[n - 1]});`"
    >
      <div
        class="see-more"
        v-if="n == maxPreviewItems && pictures.length > maxPreviewItems"
      >
        <span>+{{ pictures.length - maxPreviewItems }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GalleryPreview",
  props: {
    pictures: Array,
  },
  created: function () {
    this.maxPreviewItems = Math.min(this.pictures.length, 4);
  },
};
</script>

<style lang="scss" scoped>
.preview-grid-wrapper {
  border-radius: $default-radius $default-radius 0 0;
  .preview-grid {
    height: 300px;
    position: relative;
    flex-grow: 1 !important;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    //border: 1px solid var(--background-app);

    @include mobile {
      height: 150px;
    }
  }

  .see-more {
    display: grid;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 300px;
    line-height: 100%;
    text-align: center;
    color: #ffffff;
    font-size: 2.2rem;
    font-weight: bold;
    background-color: rgba($color: #000000, $alpha: 0.8);
    @include mobile {
      height: 150px;
    }
  }
}
</style>
