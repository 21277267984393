<template>
  <div>
    <article class="card">
      <div class="card-content">
        <router-link :to="`/post/${post.slug}`" class="is-block">
          <template v-if="post.gallery.length">
            <img-comparison-slider
              v-if="post.gallery.length >= 2 && post.acf.compare_slider"
            >
              <img slot="first" :src="post.gallery[0]" />
              <img slot="second" :src="post.gallery[1]" />
              <Icon slot="handle" name="Slider" class="slider-handle"
                ><CodeOutline
              /></Icon>
            </img-comparison-slider>
            <template v-else>
              <GalleryPreview
                v-if="post.gallery.length > 1"
                :pictures="post.gallery"
              />
              <ImagePreview v-else :url="post.gallery[0]" />
            </template>
          </template>
          <video
            v-if="post.acf.video"
            :id="`player-${post.slug}`"
            :src="post.acf.video.url"
            controls
          ></video>
        </router-link>
        <div class="card-text">
          <router-link :to="`/post/${post.slug}`">
            <h3 class="title is-5" v-html="post.title.rendered"></h3>
            <div class="subtitle">{{ post.date | moment("LLL") }}</div>
          </router-link>
          <div>
            <router-link
              class="comment-link"
              :to="`/post/${post.slug}#comments`"
            >
              <span
                class="comment-count has-text-primary"
                v-if="post._embedded.replies"
              >
                {{ post._embedded.replies[0].length }}</span
              >
              <span class="icon is-medium has-text-primary">
                <Icon name="Comments"><ChatbubblesOutline /></Icon>
              </span>
            </router-link>
            <LikeButton :post="post" />
            <router-link :to="`/post/${post.slug}`" class="icon is-medium">
              <Icon name="View"><ArrowForwardOutline /></Icon>
            </router-link>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import ImagePreview from "@/components/ImagePreview.vue";
import GalleryPreview from "@/components/GalleryPreview.vue";
import LikeButton from "@/components/LikeButton.vue";

export default {
  name: "PostPreview",
  components: {
    ImagePreview,
    GalleryPreview,
    LikeButton,
  },
  props: {
    post: Object,
  },
};
</script>

<style lang="scss" scoped>
.card {
  background-color: var(--background-card);
  border-radius: $default-radius;
  margin: 4px auto 8px auto;

  .title {
    color: var(--text-title);
  }
  .subtitle {
    color: var(--text-subtitle);
    font-size: 0.9rem;
  }

  .excerpt {
    color: var(--text-normal);
  }

  .comment-link {
    line-height: 100%;
    .comment-count {
      font-size: 1.2rem;
      vertical-align: text-top;
    }
  }

  video {
    pointer-events: none;
    width: 100%;
    max-height: $card-media-max-height;
  }

  img-comparison-slider {
    max-height: $card-media-max-height;
  }

  &:not(:first-child) {
    margin-top: 24px;
  }
  .card-content {
    padding: 0 0 0.2rem 0;

    .card-text {
      padding: 10px 15px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }
  }

  .icon {
    margin: auto 2px;
    color: var(--text-subtitle);
    font-size: 26px;
    img {
      width: 28px;
      height: 28px;
    }
  }
}
</style>
