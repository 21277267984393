import Client from "@wp-headless/client";
import Transport from "./fetch-transport";

const fetchTransport = new Transport({
  credentials: "include",
  redirect: "manual",
});
const wpClient = new Client("https://life.w-bound.us/wp-json", fetchTransport);
wpClient.likes = () => wpClient.namespace("extend/v1").resource("likes");
wpClient.unlikes = () => wpClient.namespace("extend/v1").resource("unlikes");

export default wpClient;
