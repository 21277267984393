<template>
  <div
    v-if="
      !previous || $moment(current).fromNow() != $moment(previous).fromNow()
    "
    class="column is-full is-two-thirds-widescreen"
  >
    <h5 class="subtitle is-5 time-heading has-text-weight-bold">
      <time>
        {{ current | moment("from", "now") }}
      </time>
    </h5>
  </div>
</template>
<script>
export default {
  props: ["current", "previous"],
};
</script>
