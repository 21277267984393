import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import { fetchUser } from "@/plugins/auth.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/post/:slug",
    name: "Post",
    component: () =>
      import(/* webpackChunkName: "post" */ "../views/SinglePost.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/error",
    name: "Error",
    component: () =>
      import(/* webpackChunkName: "error" */ "../views/Error.vue"),
  },
  {
    path: "/lost",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "lost" */ "../views/NotFound.vue"),
  },
  {
    path: "/pending",
    name: "Pending",
    component: () =>
      import(/* webpackChunkName: "pending" */ "../views/Pending.vue"),
  },
  {
    path: "/denied",
    name: "Denied",
    component: () =>
      import(/* webpackChunkName: "denied" */ "../views/Denied.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const noAuthRoute = ["Error", "Pending", "Denied", "NotFound"];
  if (noAuthRoute.includes(to.name)) {
    next();
    return;
  }

  try {
    const user = await fetchUser();
    if (user.next) {
      next({
        name: user.next,
      });
    } else {
      next();
    }
  } catch (error) {
    console.log("Fail to fetch user");
    console.log(error);
  }
});

export default router;
