<template>
  <div class="tags is-centered">
    <span class="icon">
      <svg
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        class="filter w-6 h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
        ></path>
      </svg>
    </span>
    <span class="tag" :class="{ 'is-primary': !selected }" @click="selectTag()">
      All
    </span>
    <span
      v-for="item in items"
      :key="item.id"
      class="tag"
      :class="{ 'is-primary': selected == item.id }"
      @click="selectTag(item.id)"
    >
      {{ item.name }}
    </span>
  </div>
</template>

<script>
export default {
  name: "TagFilter",
  props: {
    items: Array,
  },
  data: function () {
    return {
      selected: null,
    };
  },
  methods: {
    selectTag: function (id) {
      if (id === this.selected) {
        this.selected = null;
      } else {
        this.selected = id;
      }
      this.$emit("tag-selected", this.selected);
    },
  },
};
</script>

<style lang="scss" scoped>
.tags {
  justify-content: center;

  .tag {
    margin-bottom: 0;
    font-size: 1rem;
    text-transform: capitalize;
    cursor: pointer;
    background-color: var(--background-app-alt);
    color: var(--text-normal);
  }
  .icon {
    margin-right: 6px;
  }
}
</style>
