<template>
  <div class="has-text-centered">
    <img v-if="url.endsWith('.gif')" :src="url" />
    <div
      v-else
      class="card-image"
      :style="`background-image: url(${url});`"
    ></div>
  </div>
</template>

<script>
export default {
  name: "ImagePreview",
  props: {
    url: String,
  },
};
</script>

<style lang="scss" scoped>
.card-image {
  min-width: 100%;
  height: $card-media-max-height;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: $default-radius $default-radius 0 0;
}
</style>
