import qsEncode from "@wp-headless/qs-encode";
import { authRedirectIfNeeded, setUser } from "@/plugins/auth.js";

export class HTTPError extends Error {
  constructor(response) {
    super(response.statusText);
    this.name = "HTTPError";
    this.response = response;
  }
}

export function isObject(value) {
  return (
    value !== null &&
    typeof value === "object" &&
    value instanceof Array === false
  );
}

function merge(a, b) {
  return {
    ...(isObject(a) ? a : {}),
    ...(isObject(b) ? b : {}),
  };
}

export function hasBody(method) {
  return "POST PUT PATCH".indexOf(method) > -1;
}

export default class Transport {
  constructor(options = {}) {
    this.options = options;
  }
  request(input, options = {}) {
    options = merge(options, this.options);
    options.method = options.method ? options.method.toUpperCase() : undefined;

    if (options.json && hasBody(options.method)) {
      options.body = JSON.stringify(options.json);
      options.headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
      };
    }

    if (options.queryParams) {
      if (options.json && hasBody(options.method) === false) {
        options.queryParams = merge(options.queryParams, options.json);
      }
      input = input + "?" + qsEncode(options.queryParams);
    }

    return fetch(input, options).then((response) => {
      if (authRedirectIfNeeded(response)) {
        return;
      }

      switch (response.status) {
        case 403:
          setUser({ next: "Pending" });
          location.reload();
          return;
        case 401:
          setUser({ next: "Denied" });
          location.reload();
          return;
      }

      return response.json().then((data) => {
        if (!response.ok) {
          throw new HTTPError(data);
        }
        return data;
      });
    });
  }
}
