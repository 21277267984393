<template>
  <a
    class="btn-icon"
    href="#"
    @click.prevent="update"
    :class="{ active: liked }"
  >
    <Icon name="Like" v-show="!loading"><HeartOutline /></Icon>
    <img
      src="../assets/loading.svg"
      alt="loading"
      v-show="loading"
      style="width: 22px; height: 22px"
    />
  </a>
</template>

<script>
import { getUser } from "@/plugins/auth.js";
import wp from "@/plugins/wordpress.js";

export default {
  name: "LikeButton",
  props: {
    post: Object,
  },
  data: function () {
    return {
      liked: null,
      total: 0,
      loading: false,
    };
  },
  created: async function () {
    this.user = getUser();
    const likes = this.post.acf.likes || [];
    this.total = likes.length;
    this.liked = likes.indexOf(this.user.id) > -1;
  },
  methods: {
    update: function () {
      const service = this.liked ? wp.unlikes : wp.likes;
      this.loading = true;
      service()
        .create({
          post_id: this.post.id,
        })
        .then(() => {
          this.liked = !this.liked;
          if (this.liked) {
            this.total++;
          } else {
            this.total--;
          }
        })
        .finally(() => {
          this.loading = false;
        });
      this.$emit("updated", this.liked, this.total);
    },
  },
};
</script>

<style lang="scss" scoped>
.active {
  .ionicon {
    color: red;
  }
}
</style>
