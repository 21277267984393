const storageKey = "wbound-user";

export async function fetchUser() {
  if (sessionStorage.getItem(storageKey)) {
    const currentUser = getUser();
    if (!currentUser.next) {
      return currentUser;
    }
    // No cache for redirection (`next`).
    sessionStorage.removeItem(storageKey);
  }

  const authCheckRequest = await fetch(
    "https://life.w-bound.us/wp-json/proxy/v1/me",
    {
      credentials: "include",
      cache: "no-cache",
      redirect: "manual",
    }
  );

  if (authRedirectIfNeeded(authCheckRequest)) {
    return;
  }

  switch (authCheckRequest.status) {
    case 403:
      return setUser({ next: "Pending" });
    case 401:
      return setUser({ next: "Denied" });
  }

  return authCheckRequest
    .json()
    .then((data) => {
      return {
        id: data.id,
        email: data.email,
        name: data.name,
        picture: data.picture,
      };
    })
    .then(setUser);
}

export function authRedirectIfNeeded(request) {
  const authURL = `https://vouch.w-bound.us/login?url=${window.location}`;
  if (request.type == "opaqueredirect" || request.status == 302) {
    sessionStorage.removeItem(storageKey);
    window.location.href = authURL;
    return true;
  }
  return false;
}

export function getUser() {
  try {
    return JSON.parse(sessionStorage.getItem(storageKey));
  } catch (error) {
    console.log(error);
  }
}

export function setUser(user) {
  sessionStorage.setItem(storageKey, JSON.stringify(user));
  return user;
}
