<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-box navbar-start">
      <router-link
        v-if="$route.params.slug"
        alt="back"
        class="navbar-item back-button"
        to="/"
      >
        <Icon name="Back"><ArrowBackOutline /></Icon>
      </router-link>
    </div>
    <div class="navbar-box navbar-brand">
      <router-link class="navbar-item" to="/">
        <h1 class="is-size-4">W-life {{ emoji }}</h1>
      </router-link>
    </div>
    <div class="navbar-box navbar-end">
      <router-link class="navbar-item" to="/about">About</router-link>
      <slot></slot>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Topbar",
  data: function () {
    return {
      emoji: "🤘",
    };
  },
  created: async function () {
    this.pickEmoji();
  },
  watch: {
    $route() {
      this.pickEmoji();
    },
  },
  methods: {
    pickEmoji: async function () {
      const emojis = [
        "🍔",
        "🤘",
        "🏖️",
        "👋",
        "✌️",
        "🤙",
        "🐳",
        "🥞",
        "😃",
        "🥯",
        "🍺",
        "🧁",
        "🌮",
        "🌯",
        "🍻",
      ];
      this.emoji = emojis[Math.floor(Math.random() * emojis.length)];
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.02);
  justify-content: space-between;
  align-content: center;
  background-color: var(--background-card);

  .navbar-box {
    flex: 1;
    display: flex;
    justify-content: center;

    &:first-child {
      justify-content: flex-start;
      & > span {
        margin-right: auto;
      }
    }

    &:last-child {
      justify-content: flex-end;
      & > span {
        margin-left: auto;
      }
    }
  }

  a {
    color: var(--text-normal);

    &:hover,
    &:active,
    &:focus {
      background-color: var(--background-app-alt);
    }
  }

  & > div {
    flex-shrink: 0;
  }

  .navbar-brand {
    justify-content: center;
    align-items: center;
    h1 {
      font-family: "Pacifico", cursive;
    }

    .back-button {
      font-size: 1.7rem;
    }
  }
}
</style>
