<template>
  <div id="app" :class="[isDark ? 'is-dark' : 'is-light']">
    <Topbar>
      <DarkMode @updated="isDark = $event" />
    </Topbar>
    <keep-alive>
      <router-view :key="$route.fullPath" />
    </keep-alive>
  </div>
</template>

<script>
import Topbar from "@/components/Topbar.vue";
import DarkMode from "./components/DarkMode.vue";

export default {
  components: {
    Topbar,
    DarkMode,
  },
  data: function () {
    return {
      isDark: null,
    };
  },
};
</script>

<style lang="scss">
// Theme light
:root,
body #app.is-light {
  --input-background: #{$input-background-light};
  --background-app: #{$lighter};
  --background-app-alt: #{$light};
  --background-card: #{$lighter};
  --text-title: #{$text-title-light};
  --text-normal: #{$text-normal-light};
  --text-subtitle: #{$text-subtitle-light};
}

@media (prefers-color-scheme: light), (prefers-color-scheme: no-preference) {
  :root,
  body #app {
    --input-background: #{$input-background-light};
    --background-app: #{$lighter};
    --background-app-alt: #{$light};
    --background-card: #{$lighter};
    --text-title: #{$text-title-light};
    --text-normal: #{$text-normal-light};
    --text-subtitle: #{$text-subtitle-light};
  }
}

// Theme dark
body #app.is-dark {
  --input-background: #{$input-background-dark};
  --background-app: #{$dark};
  --background-app-alt: #{$darker};
  --background-card: #{$darker};
  --text-title: #{$text-title-dark};
  --text-normal: #{$text-normal-dark};
  --text-subtitle: #{$text-subtitle-dark};
}
@media (prefers-color-scheme: dark) {
  :root,
  body #app {
    --input-background: #{$input-background-dark};
    --background-app: #{$dark};
    --background-app-alt: #{$darker};
    --background-card: #{$darker};
    --text-title: #{$text-title-dark};
    --text-normal: #{$text-normal-dark};
    --text-subtitle: #{$text-subtitle-dark};
  }
}

html,
body,
#app {
  min-height: 100vh;
  color: var(--text-normal);
  background-color: var(--background-app);
  transition: background-color cubic-bezier(0.165, 0.84, 0.44, 1) 300ms;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .title {
    color: var(--text-title);
  }

  .subtitle {
    color: var(--text-subtitle);
  }
}
</style>
