<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
    :aria-labelledby="name"
    class="ionicon"
  >
    <title :id="name" lang="en">{{ name }}</title>
    <g :fill="color">
      <slot />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    viewBox: { type: String, default: "0 0 512 512" },
    name: { type: String, default: "" },
    width: { type: [Number, String], default: 30 },
    height: { type: [Number, String], default: 30 },
    color: { type: String, default: "currentColor" },
  },
};
</script>
