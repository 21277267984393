<template>
  <a
    v-on:click="toggleTheme()"
    aria-label="Toggle dark mode"
    class="navbar-item"
  >
    <svg
      v-if="!isDark"
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      class="bi bi-brightness-low-fill"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0zM8.5 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 11a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm5-5a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm-11 0a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9.743-4.036a.5.5 0 1 1-.707-.707.5.5 0 0 1 .707.707zm-7.779 7.779a.5.5 0 1 1-.707-.707.5.5 0 0 1 .707.707zm7.072 0a.5.5 0 1 1 .707-.707.5.5 0 0 1-.707.707zM3.757 4.464a.5.5 0 1 1 .707-.707.5.5 0 0 1-.707.707z"
      />
    </svg>
    <svg
      v-else
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      class="bi bi-brightness-high"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        d="M8 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z"
      />
    </svg>
  </a>
</template>

<script>
export default {
  name: "Darkmode",
  data: function () {
    return {
      isDark: null,
    };
  },
  created: function () {
    this.isDark =
      "overrideDark" in localStorage
        ? JSON.parse(localStorage.overrideDark)
        : matchMedia("(prefers-color-scheme: dark)").matches;
    this.$emit("updated", this.isDark);
  },
  methods: {
    toggleTheme: function () {
      this.isDark = !this.isDark;
      localStorage.overrideDark = this.isDark;
      this.$emit("updated", this.isDark);
    },
  },
};
</script>

<style lang="scss">
a {
  display: inline-block;

  svg {
    height: 24px;
    width: 24px;
  }
}
</style>
